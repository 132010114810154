import React, {
  createContext,
  useMemo,
  useCallback,
  useState,
  useContext,
  useEffect,
  ReactElement,
} from 'react';
import {
  AuthnTransaction,
  AuthState,
  OktaAuth,
  OktaAuthOptions,
} from '@okta/okta-auth-js';
import { AVAILABLE_ENV, ENV } from '@simon/config/env';
import { generateSsoRouteLink } from '@simon/core/utils/generateSsoLink';
import { EVENT, LOGIN_MODE, AUTH_ORIGINAL_URL_KEY } from './constants';
import authService from './authService';
import { EMPTY_OBJECT } from '@simon/core/constants/globals';

import {
  ICNUser,
  ILoginMode,
  IMasterUserRecord,
  IMasterUserResponse,
  ITheme,
} from './types';
import {
  simonApi,
  icnApi,
  setICNApiPrefix,
  setCSRFToken,
  postICNSignIn,
} from './utils';
import ICapitalLogo from '@simon/ui/ICapitalLogo';
import { isAbsoluteURL } from '@simon/core/utils/url';
import { BroadcastChannel } from 'broadcast-channel';

type IloginCredentialsOpts = {
  username?: string;
  password?: string;
  experience?: string;
  token?: string;
  loginMode?: ILoginMode;
};

type IAuthContext = {
  authState: AuthState;
  authClient: OktaAuth;
  transaction: AuthnTransaction;
  setTransaction: (transaction: AuthnTransaction) => void;
  resumedTransaction: AuthnTransaction;
  setResumedTransaction: (transaction: AuthnTransaction) => void;
  expiredPasswordTxCtx: AuthnTransaction;
  setExpiredPasswordTxCtx: (transaction: AuthnTransaction) => void;
  selectedFactorCtx: any;
  setSelectedFactorCtx: (factor: any) => void;
  disposeResumedTransaction: () => void;
  loginCredentials: (opts: IloginCredentialsOpts) => Promise<AuthnTransaction>;
  loginSessionToken: (sessionToken?: string) => Promise<void>;
  loginSSO: (user: IMasterUserRecord) => Promise<void>;
  logout: () => Promise<void>;
  revokeAccessToken: () => Promise<void>;
  isLoading: boolean;
  isSwitchingUser: boolean;
  getOriginalUrl: () => string | undefined;
  setOriginalUrl: (url: string) => void;
  removeOriginalUrl: () => void;
  getMasterUser: (email: string) => Promise<IMasterUserResponse>;
  theme: ITheme;
  isSynchronized: boolean;
  isAuthenticated: boolean | ICNUser;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
} & Pick<
  IAuthProviderProps,
  | 'env'
  | 'onEvent'
  | 'getRememberedMasterUser'
  | 'saveRememberedMasterUser'
  | 'removeRememberedMasterUser'
>;

type IAuthProviderProps = {
  env?: 'alpha' | 'qa' | 'prod' | string;
  onEvent?: (event: string, data?: any) => void;
  getRememberedMasterUser?: () => IMasterUserResponse | null;
  saveRememberedMasterUser?: (d: IMasterUserResponse) => void;
  removeRememberedMasterUser?: () => void;
  children: ReactElement;
  config?: OktaAuthOptions;
  onLogout?: (args?: { isSwitch?: boolean }) => void;
  onBeforeGetToken?: () => void;
  enableICNflow?: boolean;
  icnApiPrefix?: string;
  masterUserApiPrefix?: string;
  assetsPrefix?: string;
};

const AuthContext = createContext<IAuthContext>(null);

// Default function to get remembered master user
const defaultGetRememberedMasterUser = () => {
  try {
    const masterUser = JSON.parse(localStorage.getItem('auth-remember'));

    // validate fields and delete stale store
    if (
      masterUser &&
      (!masterUser.email ||
        !Array.isArray(masterUser.users) ||
        !masterUser.users.every(user =>
          ['login', 'loginMode'].every(key => user.hasOwnProperty(key))
        ))
    ) {
      defaultRemoveRememberedMasterUser();
      return null;
    }
    return masterUser;
  } catch (e) {
    return null;
  }
};

// Default function to save remembered master user
const defaultSaveRememberedMasterUser = masterUser =>
  masterUser &&
  Object.keys(masterUser).length > 0 &&
  localStorage.setItem('auth-remember', JSON.stringify(masterUser));

// Default function to remove remembered master user
const defaultRemoveRememberedMasterUser = () =>
  localStorage.removeItem('auth-remember');

const defaultTheme: ITheme = {
  showFooter: true,
  // @ts-ignore
  logo: <ICapitalLogo width={122} height={62} translucent />,
};

export function AuthProvider({
  env = ENV,
  children,
  config: _config = EMPTY_OBJECT,
  onEvent: _onEvent,
  onLogout,
  onBeforeGetToken,
  getRememberedMasterUser = defaultGetRememberedMasterUser,
  saveRememberedMasterUser = defaultSaveRememberedMasterUser,
  removeRememberedMasterUser = defaultRemoveRememberedMasterUser,
  enableICNflow,
  icnApiPrefix,
  masterUserApiPrefix,
  assetsPrefix,
}: IAuthProviderProps) {
  const broadcastChannel = useMemo(() => new BroadcastChannel('auth'), []);

  const getMasterUser = useCallback(
    async email => {
      if (enableICNflow) {
        return icnApi.post(
          `${masterUserApiPrefix || ''}/v1/master-user`,
          { email },
          {
            authorization: false,
          }
        ) as Promise<IMasterUserResponse>;
      }
      return simonApi.post(
        `${masterUserApiPrefix || ''}/v2/master-user`,
        { email },
        {
          authorization: false,
        }
      ) as Promise<IMasterUserResponse>;
    },
    [enableICNflow, masterUserApiPrefix]
  );

  const clientConfig = useMemo(() => {
    if (typeof getMasterUser !== 'function') {
      throw new Error(`AuthProvider: getMasterUser function is required.`);
    }

    const host =
      typeof window !== 'undefined'
        ? window.location.host.replace('www.', '')
        : '';

    const issuerDomain =
      // FIG QA
      host === 'qa.simon.figdevteam.com'
        ? 'https://auth.int.simon.figdevteam.com'
        : // FIG PROD
        host === 'simon.figmarketing.com'
        ? 'https://auth.simon.figmarketing.com'
        : // SIMON PROD
        env === AVAILABLE_ENV.PROD
        ? 'https://auth.simonmarkets.com'
        : // SIMON QA
        env === AVAILABLE_ENV.QA
        ? 'https://auth.int.simonmarkets.com'
        : // SIMON DEV
          'https://auth.dev.simonmarkets.com';

    const simonDevIssuer = `${issuerDomain}/oauth2/aushyww1gbLKeY8Gv1d7`;

    const conf: OktaAuthOptions = {
      issuer:
        env === AVAILABLE_ENV.DEV
          ? simonDevIssuer
          : env === AVAILABLE_ENV.QA
          ? `${issuerDomain}/oauth2/ausesty3dmANUL4lH0h7`
          : env === AVAILABLE_ENV.PROD
          ? `${issuerDomain}/oauth2/aus12xhtrm4lv59w32p7`
          : // default to Dev issuer
            simonDevIssuer,
      redirectUri:
        typeof window !== 'undefined'
          ? `${window.location.origin}${window.location.pathname}`
          : 'http://localhost:3000',
      scopes: ['openid', 'profile', 'email'],
      clientId:
        env === AVAILABLE_ENV.PROD
          ? '0oadjggdmyOlW19D12p7'
          : env === AVAILABLE_ENV.QA
          ? '0oa11csbex44SlWOi0h8'
          : '0oai0nnaw2taX58Hp1d7',
      transformAuthState: async (authClient, authState) => {
        // Cleanup tokens
        const cleanup = async () => {
          if (authState.accessToken) {
            await authClient.revokeAccessToken();
          }
          authClient.tokenManager.clear();
          authState.isAuthenticated = false;
          return authState;
        };

        // Verify that the access token hasn't expired
        if (
          authState.accessToken &&
          !authClient.tokenManager.hasExpired(authState.accessToken)
        ) {
          // Decode claims from JWT.
          // https://github.com/okta/okta-auth-js/blob/64a548b9f0037887d1bf8908a76bf9f3848ee0d6/lib/oidc/decodeToken.ts#L17
          if (!Object.keys(authState.accessToken.claims).length) {
            try {
              authState.accessToken.claims = JSON.parse(
                atob(authState.accessToken.accessToken.split('.')[1])
              );
            } catch (e) {
              // void
            }
          }
          authState.isAuthenticated = true; // not needed but let's be explicit
          return authState;
        }

        // Not logged-in or accessToken expired
        return cleanup();
      },
      tokenManager: {
        // Explicitly disable storage sync (sessionStorage doesn't support it anyway) because of an incompatibility with our main useEffect that fetches the token
        // It causes bugs such as not being able to logout in cases when SIMON is loaded multiple times in an iframe on the same page (FIG)
        // todo: fix the main effect. Probably remove dep on isLoggingOut.
        syncStorage: false,
        storage: 'sessionStorage',
      },
      // Backend is validating it anyway so there is no risk
      ignoreLifetime: true,
    };

    // Override with user config
    Object.assign(conf, _config);

    return conf;
  }, [_config, env, getMasterUser]);

  // Initialize the client
  const authClient = useMemo(() => {
    const client = new OktaAuth(clientConfig);
    // Update the singleton service so that it's accessible outside of React context
    authService.setAuthClient(client);
    return client;
  }, [clientConfig]);

  // onEvent callback used to hook reporters, analytics etc.
  const onEvent = useCallback(
    (e, payload = {}) => {
      // add additional props
      payload = { ...payload, authClient };
      if (clientConfig.devMode) {
        // eslint-disable-next-line no-console
        console.debug('useAuth, onEvent', e, payload);
      }
      if (typeof _onEvent === 'function') {
        return _onEvent(e, payload);
      }
    },
    [_onEvent, authClient, clientConfig.devMode]
  );

  useEffect(() => {
    onEvent(EVENT.Initialized, { clientConfig });
  }, [clientConfig, onEvent]);

  // Auth state - https://github.com/okta/okta-auth-js#authstatemanager
  // {
  //   isAuthenticated: false,
  //   masterUser: null,
  //   idToken: null,
  //   accessToken: null,
  //   error: null,
  // }
  const [authState, setAuthState] = useState(() =>
    authClient.authStateManager.getAuthState()
  );

  const [transaction, setTransaction] = useState<AuthnTransaction>();
  const [expiredPasswordTxCtx, setExpiredPasswordTxCtx] =
    useState<AuthnTransaction>();
  const [selectedFactorCtx, setSelectedFactorCtx] = useState();

  const [resumedTransaction, setResumedTransaction] =
    useState<AuthnTransaction>();

  const disposeResumedTransaction = useCallback(() => {
    setResumedTransaction(null);
  }, []);

  // Used to show a "global" loader until consumer knows for sure if user is authenticated or not.
  const [isInitialized, setIsInitialized] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | ICNUser>(
    authState?.isAuthenticated
  );
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isSwitchingUser, setIsSwitchingUser] = useState(false);
  const [theme, setTheme] = useState<ITheme>(
    enableICNflow ? null : defaultTheme
  );
  // This will be extracted in a separate Provider after we have a long term plan.
  useEffect(() => {
    if (!enableICNflow) return;

    if (icnApiPrefix) {
      setICNApiPrefix(icnApiPrefix);
    }

    (async () => {
      try {
        const {
          csrf_token,
          public_logo,
          icn_bootstrap: {
            user,
            white_label_partner: { disclaimer = null } = {},
          },
        } = await icnApi.get('/v1/users/react_bootstrap_identity');

        setCSRFToken(csrf_token);
        if (user) {
          setIsAuthenticated(user);
        }

        setTheme({
          ...defaultTheme,
          showDevLogin: true,
          logo: (
            <img
              src={
                isAbsoluteURL(public_logo)
                  ? public_logo
                  : `${assetsPrefix || ''}${public_logo}`
              }
            />
          ),
          disclaimers: disclaimer && (
            // eslint-disable-next-line react/no-danger
            <div dangerouslySetInnerHTML={{ __html: disclaimer }} />
          ),
        });
      } catch (e) {
        setTheme(defaultTheme);
      }
    })();
  }, [assetsPrefix, enableICNflow, icnApiPrefix]);

  // Subscribe to auth state changes and save in local state.
  useEffect(() => {
    // https://github.com/okta/okta-auth-js#running-as-a-service
    authClient.start();

    const listener = (authState: AuthState) => {
      setAuthState(authState);
      setIsAuthenticated(authState.isAuthenticated);

      // Mark auth state as initialized after a successful login.
      if (authState.isAuthenticated) {
        setIsInitialized(true);
        onEvent(EVENT.LoginSuccess);
        setIsSwitchingUser(false);
      }
    };
    authClient.authStateManager.subscribe(listener);

    return () => {
      authClient.stop();
      authClient.authStateManager.unsubscribe(listener);
    };
  }, [authClient, onEvent]);

  // Sync React state with external authService
  useEffect(() => {
    authService._isAuthenticated = !!isAuthenticated;
    if (isAuthenticated) {
      authService.setIsImpersonationLoading(false);
    }
  }, [isAuthenticated]);

  const loginSessionToken = useCallback(
    async (sessionToken?: string) => {
      if (typeof onBeforeGetToken === 'function') {
        await onBeforeGetToken();
      }
      const res = await authClient.token.getWithoutPrompt({
        sessionToken,
      });
      authClient.tokenManager.add('accessToken', res.tokens.accessToken);
    },
    [authClient.token, authClient.tokenManager, onBeforeGetToken]
  );

  // Verify existing tokens
  // Will also attempt to renew them if it's close to expiration and throw if it fails.
  // https://github.com/okta/okta-auth-js#tokenmanagergetkey
  useEffect(() => {
    // authState is pending
    if (!authState || isLoggingOut) return;

    (async () => {
      try {
        const accessToken = await authClient.tokenManager.get('accessToken');
        if (accessToken && !authClient.tokenManager.hasExpired(accessToken)) {
          // token is valid
          setIsInitialized(true);
        } else {
          // No token or it has been removed due to expiration or error while renewing
          await loginSessionToken();
          // Success, the "Mark as initialized" authStateManager listener above will mark as initialized.
          // This is to avoid flashing the login page right before logging in.
          // loginSessionToken() is async but tokenManager.setTokens() doesn't emit immediately and can't be awaited.
        }
      } catch (e) {
        // Request sessionStorage from other tabs
        await broadcastChannel.postMessage({ msg: 'getAuthState' });

        setIsInitialized(true);
      }
    })();
  }, [
    authClient,
    authState,
    broadcastChannel,
    isLoggingOut,
    loginSessionToken,
    onEvent,
  ]);

  // Resume transaction
  useEffect(() => {
    (async () => {
      // Already resumed, disposed or nothing to resume
      if (
        resumedTransaction ||
        resumedTransaction === null ||
        !authClient.tx.exists()
      )
        return;

      try {
        const transaction = await authClient.tx.resume();
        onEvent(EVENT.TransactionResume, transaction);
        // keep another copy since we no longer pass transaction via navigate() state
        setTransaction(transaction);
        setResumedTransaction(transaction);
      } catch (e) {
        console.warn(`Failed to resume transaction`, e);
      }
    })();
  }, [onEvent, authClient.tx, resumedTransaction]);

  const getOriginalUrl = useCallback(() => {
    const url = sessionStorage.getItem(AUTH_ORIGINAL_URL_KEY);
    if (url) {
      return decodeURIComponent(url);
    }
  }, []);

  const setOriginalUrl = useCallback(
    url =>
      sessionStorage.setItem(AUTH_ORIGINAL_URL_KEY, encodeURIComponent(url)),
    []
  );

  const removeOriginalUrl = useCallback(
    () => sessionStorage.removeItem(AUTH_ORIGINAL_URL_KEY),
    []
  );

  const logout = useCallback(
    async (opts = {}) => {
      const { isAuthenticated } = authState || {};
      if (isAuthenticated && !isLoggingOut) {
        setIsLoggingOut(true);

        await Promise.all([
          authClient.revokeAccessToken(),
          authClient.closeSession(),
        ]);

        // don't save original URI from previous session
        removeOriginalUrl();

        if (typeof onLogout === 'function') {
          await onLogout(opts);
        }

        // logout from all tabs
        broadcastChannel.postMessage({ msg: 'logout' });

        setIsLoggingOut(false);
      }
    },
    [
      authClient,
      authState,
      broadcastChannel,
      isLoggingOut,
      onLogout,
      removeOriginalUrl,
    ]
  );

  // Cross-tab communication
  useEffect(() => {
    // Broadcast new state to other tabs
    authClient.authStateManager.subscribe(authState => {
      broadcastChannel.postMessage({ msg: 'authState', authState });
    });

    const listener = d => {
      onEvent(EVENT.CrossTabMsg, d);

      // Inherit successful login. (only if current session is not also authenticated)
      if (
        d.msg === 'authState' &&
        d.authState?.isAuthenticated &&
        !authState?.isAuthenticated
      ) {
        return authClient.tokenManager.setTokens(d.authState);
      }

      // Logout
      if (d.msg === 'logout') {
        return logout();
      }

      // Newly created tab is requesting auth state
      if (d.msg === 'getAuthState') {
        broadcastChannel.postMessage({ msg: 'authState', authState });
      }
    };

    broadcastChannel.addEventListener('message', listener);
    return () => broadcastChannel.removeEventListener('message', listener);
  }, [authClient, authState, broadcastChannel, logout, onEvent]);

  // Login using username and password
  const loginCredentials = useCallback(
    async ({ username, password, loginMode, token }: IloginCredentialsOpts) => {
      // If authenticated -> switching users
      if (isAuthenticated) {
        setIsSwitchingUser(true);
        // logout of current session
        await logout({ isSwitch: true });
      }

      // ICN login
      if (
        loginMode === LOGIN_MODE.ICNUsernamePassword ||
        username === 'nikola.kalinov@icapitalnetwork.com'
      ) {
        const { success, login_landing_page } = await postICNSignIn(
          username,
          password
        );
        window.location.href = login_landing_page;
        return setIsAuthenticated(success);
      }

      // SIMON-Okta login
      let transaction = null;
      try {
        transaction = await authClient.signInWithCredentials({
          username,
          password,
          // @ts-ignore Undocumented property
          token,
        });
      } catch (e) {
        // wrong password
        onEvent(EVENT.LoginError, {
          loginMode: LOGIN_MODE.UsernamePassword,
          error: e,
        });
        // Normally we should do setIsSwitchingUser(false) here
        // but since simonmarkets-web will anyway refresh the page on logout we want to see the error feedback for a bit.
        // onboarding-tool doesn't support switching users so that's ok.
        throw e;
      }

      // could be SUCCESS, PASSWORD_WARN or PASSWORD_EXPIRED at this point
      // https://github.com/okta/okta-auth-js/blob/master/docs/authn.md#transactionstatus
      if (transaction.status === 'SUCCESS') {
        try {
          // get tokens using the sessionToken we just obtained
          await loginSessionToken(transaction.sessionToken);

          onEvent(EVENT.LoginSuccess, {
            type: LOGIN_MODE.UsernamePassword,
            status: transaction.status,
          });
        } catch (e) {
          onEvent(EVENT.LoginError, {
            loginMode: LOGIN_MODE.UsernamePassword,
            error: `[Auth] loginCredentials: signInWithCredentials() succeeded but loginSessionToken() threw ${e.message}`,
          });
          throw e;
        }
      }

      return transaction;
    },
    [authClient, isAuthenticated, loginSessionToken, logout, onEvent]
  );

  // Login with SSO (redirect) using ssoPrefix network configuration
  const loginSSO = useCallback(
    async (user: IMasterUserRecord) => {
      const redirectToSSO = async (ssoUrl?, ssoPrefix?) => {
        // If authenticated -> switching users
        if (authState?.isAuthenticated) {
          setIsSwitchingUser(true);
          // logout of current session
          await logout({ isSwitch: true });
        }
        const url = ssoPrefix
          ? generateSsoRouteLink(ssoPrefix, getOriginalUrl() || '/')
          : ssoUrl;

        onEvent(EVENT.LoginRedirect, {
          url,
        });
        return window.location.assign(url);
      };
      if (
        [LOGIN_MODE.SSO, LOGIN_MODE.SSOAndUsernamePassword].includes(
          user.loginMode
        )
      ) {
        // use ssoPrefix
        if (!user.ssoPrefix?.baseUrl) {
          throw new Error(
            `[Auth] Login mode is ${user.loginMode} but there is no ssoPrefix.baseUrl`
          );
        }
        return redirectToSSO(undefined, user.ssoPrefix);
      } else if (
        [LOGIN_MODE.Embedded, LOGIN_MODE.EmbeddedAndUsernamePassword].includes(
          user.loginMode
        )
      ) {
        // use embeddingInfo
        if (!user.embeddingInfo?.hostApplicationUrl) {
          throw new Error(
            `[Auth] Login mode is ${user.loginMode} but there is no embeddingInfo.hostApplicationUrl`
          );
        }
        return redirectToSSO(user.embeddingInfo.hostApplicationUrl);
      }
    },
    [authState, getOriginalUrl, logout, onEvent]
  );

  const revokeAccessToken = useCallback(async () => {
    if (authState?.isAuthenticated) {
      await authClient.revokeAccessToken();
    }
  }, [authClient, authState]);

  return (
    <AuthContext.Provider
      value={{
        authState,
        isAuthenticated,
        setIsAuthenticated,
        authClient,
        resumedTransaction,
        transaction,
        setTransaction,
        expiredPasswordTxCtx,
        setExpiredPasswordTxCtx,
        setResumedTransaction,
        selectedFactorCtx,
        setSelectedFactorCtx,
        disposeResumedTransaction,
        loginCredentials,
        loginSessionToken,
        loginSSO,
        logout,
        revokeAccessToken,
        isLoading: !authState || !isInitialized,
        isSwitchingUser,
        onEvent,
        getMasterUser,
        getRememberedMasterUser,
        saveRememberedMasterUser,
        removeRememberedMasterUser,
        getOriginalUrl,
        setOriginalUrl,
        removeOriginalUrl,
        env,
        theme,
        isSynchronized: !!authState?.accessToken?.claims.icnId,
      }}
    >
      {theme ? children : null}
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  // Try to use context if we can

  const value = useContext(AuthContext);

  // If we have no Provider in the tree, throw
  if (!value) {
    throw new Error('useAuth() used outside of AuthProvider');
  }

  return value;
}
