import { AVAILABLE_ENV, ENV } from '@simon/config/env';

const GTM_CODE = 'GTM-NZQNJCV';
const dataLayerKey = 'dataLayer';

/**
 * This function initializes Google Tag Manager (GTM).
 */
export function gtmInitialize() {
  if (!window[dataLayerKey]) {
    window[dataLayerKey] = [];

    const script = document.createElement('script');
    script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','${dataLayerKey}','${GTM_CODE}');
    `;
    document.head.insertBefore(script, document.head.childNodes[0]);

    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_CODE}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
  }
}

/**
 *
 * @param {*} obj
 * This function will do a push into the Google Tag Manager (GTM) 'dataLayer' object
 * One can use this to pass an object with any props to GTM as required
 */
export function gtmPush(obj) {
  if (!window[dataLayerKey]) {
    gtmInitialize();
  }

  window[dataLayerKey].push(obj);

  if (ENV === AVAILABLE_ENV.QA && process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line no-console
    console.log(
      `%cICN GTM tracking event: ${obj.event}`,
      'color:cornflowerblue; font-size:16px;',
      obj
    );
  }
}

export function gtmPushOldSimon(eventName, properties) {
  gtmPush({
    event: 'simon_event',
    eventDisplayName: eventName,
    simonProperties: properties,
  });
}

export function gtmSetOldSimon(type, properties = {}) {
  gtmPush({
    event: type,
    properties,
  });
}
